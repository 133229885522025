import { Component } from 'react';
import SiteForms from "@layout/siteForms";
import Application from "@components/general/application";
import RealTimeOverlay from "@components/general/real-time-overlay";


class Page extends Component {

    constructor(props) {
        super(props);
        this.state = {
            overlay: false,
            campaignDetails: null
        }
        this.timer = null;
    }


    disableExternalLinks() {
        return ![
            process.env.AUTO_LIVE_ENV_HOSTNAME,
            process.env.AUTO_SANDBOX_ENV_HOSTNAME
        ].includes(window.location.hostname);
    }


    componentDidMount() {
        if (this.disableExternalLinks()) {
            const homeLinks = document.querySelectorAll("[aria-label='Home']");
            for (let i = 0; i < homeLinks.length; i++) {
                homeLinks[i].setAttribute('href', '/')
            }
            const pageLinks = document.querySelectorAll("[data-type='env']");
            for (let i = 0; i < pageLinks.length; i++) {
                pageLinks[i].setAttribute('href', 'javascript: void(0)')
            }
        }

    }


    componentWillUnmount() {
        clearTimeout(this.timer);
    }


    setOverlay(visibility, stateName) {
        this.setState({
            overlay: visibility,
            stateName: stateName
        })
    }


    handleCampaignDetails(details) {
        this.setState({
            campaignDetails: details
        })
    }


    render() {
        return (
            <SiteForms campaignDetails={this.state.campaignDetails}>
                <div className="formContent">
                    <div className="justify-content-md-center">
                        <Application
                            campaignDetails={this.state.campaignDetails}
                            handleAction={this.setOverlay.bind(this)}
                            handleCampaignDetails={this.handleCampaignDetails.bind(this)}
                        />
                        {this.state.overlay && <RealTimeOverlay stateName={this.state.stateName}/>}
                    </div>
                </div>
           </SiteForms>
        )
    }

}


export default Page;
